
import { defineComponent } from 'vue'
import { aboutInfo } from '@/utils/webSideInfo'

export default defineComponent({
  setup () {
    const imgUrl = {
      about: require('@/assets/image/about.jpg'),
      aboutInfo: require('@/assets/image/about-info.jpg')
    }

    return {
      imgUrl,
      aboutInfo
    }
  }
})
